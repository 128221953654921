<template>
  <div class="filtres">
    <div
      v-for="category in categories"
      :key="category.id"
      class="filtre-item"
      :class="[ `filtre-level-${depth}`, category.id === activeCategoryId ? 'filtre-active' : 'filtre-unactive' ]"
    >
      <router-link :to="{ name: $route.name, query: getQuery(category), hash: '#products' }">
        <span class="filtre-name">{{ category.title[$l] || category.title.fr }} ({{ category.total }})</span>
      </router-link>

      <span
        v-if="category.nodes.length"
        class="chevron"
        @click="setActiveCategoryId(category.id === activeCategoryId ? null : category.id)"
      >
      </span>

      <div v-if="category.nodes.length">
        <CommercantFiltreRayon
          :categories="category.nodes"
          :depth="depth + 1"
          @active="child => setActiveCategoryId(category.id, child)"
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CommercantFiltreRayon',
  props: {
    categories: { type: Array },
    depth: { type: Number, default: 0 }
  },
  data: () => ({
    activeCategoryId: null
  }),
  mounted() {
    this.categories.forEach(category => {
      if (Number(this.$route.query.category) === category.id) {
        this.setActiveCategoryId(category.id)
      }
    })
  },
  methods: {
    getQuery(category) {
      return {
        category: category.id
      }
    },

    setActiveCategoryId(parentCategoryId, childCategoryId) {
      this.activeCategoryId = parentCategoryId
      this.$emit('active', parentCategoryId)
    }
  }
}
</script>
<!-- Add "scoped" attribute to limit SCSS to this component only -->
<style lang="scss" scoped>
@import '../../assets/styles/youpaq.scss';
.filtre-active .chevron::before {
  top: 0.35em;
  left: 0.35em;
  transform: rotate(135deg);
}

.chevron::before,
.filtre-unactive .chevron::before {
  border-style: solid;
  border-width: 0.15em 0.15em 0 0;
  content: '';
  display: inline-block;
  height: 0.55em;
  width: 0.55em;
  left: 0.25em;
  position: relative;
  top: 0.45em;
  transform: rotate(45deg);
  vertical-align: top;
  color: var(--purple-color);
}

.root-filtres {
  display: block !important;
}

.filtres {
  display: none;
}

.filtre-active .filtres  {
  display: block;
  padding-top: 20px;
}

.filtre-unactive .filtres {
    display: none;
}

.filtre-level-0 {
  text-transform: uppercase;
}

.filtre-level-1, .filtre-level-2 {
  text-transform: none;
}

.filtre-level-0.filtre-active a .filtre-name{
  background-color: var(--red-color);
}

.filtre-level-0 .filtres {
  margin-left: 10px;
}

.filtre-level-1 .filtres {
  margin-left: 20px;
}

.filtre-level-2 .filtres {
  margin-left: 30px;
}

.filtre-level-1,
.filtre-level-2 {
  a .filtre-name{
    background-color: transparent !important;
    color: var(--purple-color);
    padding: 0;
    &:hover {
      color: var(--red-color);
    }
  }
}

.filtre-level-1.filtre-active,
.filtre-level-2.filtre-active {
  &>a:first-child .filtre-name{
    background-color: transparent;
    color: var(--red-color);
    &:hover {
      color: darken(#E45E43, 10%);
    }
  }
}

/*
.filtre-level-1.filtre-unactive,
.filtre-level-2.filtre-unactive {
  a {
    background-color: transparent;
    color: var(--purple-color);
    &:hover {
      color: darken(var(--purple-color), 10%);
    }
  }
}

.filtre-level-1.filtre-active,
.filtre-level-2.filtre-active {
  a {
    background-color: transparent;
    color: var(--purple-color);
    &:hover {
      color: darken(var(--purple-color), 10%);
    }
  }

  &>a:first-child {
    background-color: transparent;
    color: var(--red-color);
  }
}
*/

</style>
