<template>
  <div id="commercant-filtres">
    <div v-if="campaigns.length" class="title">
      <img src="@/assets/filtres-icon.png">{{ $t('shop.promotions') }}
    </div>
    <div v-if="campaigns.length" class="row mb-5">
      <div class="filtres">
        <div v-for="campaign in campaigns" :key="campaign.key" class="filtre-item">
          <router-link :to="{ name: $route.name, query: getCampaignQuery(campaign), hash: '#products' }">
            <span class="filtre-name">{{ campaign.getName($l) }}</span>
          </router-link>
        </div>
      </div>
    </div>
    <div v-if="favourites" class="title">
      <img src="@/assets/favoris-icon.png">{{ $t('shop.favouriteTitle') }}
    </div>
    <div v-if="favourites" class="row mb-5">
      <div class="filtres">
        <div class="filtre-item">
          <router-link :to="{ name: $route.name, query: getFavouriteQuery(), hash: '#products' }">
            <span class="filtre-name">{{ $t('shop.favourite', { count: favourites }) }}</span>
          </router-link>
        </div>
      </div>
    </div>
    <div v-if="tags.length" class="title">
      <img src="@/assets/filtres-icon.png">{{ $t('shop.enviede') }}
    </div>
    <div v-if="tags.length" class="row mb-5">
      <div class="filtres">
        <div v-for="tag in tags" :key="tag.key" class="filtre-item">
          <router-link :to="{ name: $route.name, query: getTagQuery(tag), hash: '#products' }">
            <span class="filtre-name">{{ tag.key }} ({{ tag.doc_count }})</span>
          </router-link>
        </div>
      </div>
    </div>
    <div v-if="categories.length" class="title">
      <img src="@/assets/filtres-icon.png">{{ $t('shop.categories') }}
    </div>
    <div v-if="categories.length" class="row mb-5">
      <CommercantFiltreRayon :categories="categories" class="root-filtres" />
    </div>
  </div>
</template>

<script>
import CommercantFiltreRayon from '@/components/Commercant/CommercantFiltreRayon'

export default {
  name: 'CommercantFiltres',
  components: { CommercantFiltreRayon },
  props: {
    partnerId: { type: String }
  },
  data: () => ({
    tags: [],
    categories: [],
    campaigns: [],
    favourites: 0
  }),
  computed: {
    shop() {
      return this.$store.getters['shop/current']
    },
    connected() {
      return this.$store.getters['auth/connected']
    }
  },
  mounted() {
    return this.init()
  },
  methods: {
    init() {
      this.loading = true
      return Promise
        .all([
          this.$store.dispatch('shop/tags', { id: this.shop.id }),
          this.$store.dispatch('shop/categories', { id: this.shop.id }),
          this.$store.dispatch('shop/promotionsCampaigns', { id: this.shop.id })
        ])
        .then(data => {
          this.tags = data[0]
          this.categories = data[1]
          this.campaigns = data[2]
          this.$emit('loaded', true)
          if (!this.connected) {
            return
          }
          return this.$store.dispatch('user/countFavourites', { shopId: this.shop.id })
            .then(count => (this.favourites = count))
        })
        .catch(err => this.$err(err))
        .finally(() => (this.loading = false))
    },

    getTagQuery(tag) {
      return {
        tags: tag.key
      }
    },

    getCampaignQuery(campaign) {
      return {
        campaign: campaign.id
      }
    },

    getFavouriteQuery() {
      return {
        favourite: true
      }
    }
  }
}
</script>

<!--
Add "scoped" attribute to limit SCSS to this component only
<style lang="scss" scoped>
-->
<style lang="scss">
@import '../../assets/styles/youpaq.scss';

#commercant-filtres {
  text-align: center;
  padding-bottom: 30px;
  @include media-breakpoint-up(lg) {
    text-align: left;
  }
}
.title {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--purple-color);
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;

  @include media-breakpoint-up(lg) {
    justify-content: flex-start;
  }
  @include media-breakpoint-up(lg) {
    font-size: 20px;
  }

  img {
    height: 20px;
    padding-right: 10px;
  }
}
.filtres {
  display: flex;
  justify-content: center;
  max-width: 100vw;
  flex-wrap: wrap;
  @include media-breakpoint-up(md) {
    display: block;
  }
}
.filtre-item {
  padding: 0 10px 10px;
  @include media-breakpoint-up(md) {
    padding: 0px 0px 10px 0px;
  }
}
.filtre-item a {
  display: flex;
  align-items: center;
  text-decoration: none;
  display: inline-block;
}
.filtre-item a .filtre-name {
  color: white;
  background-color: var(--purple-color);
  font-weight: 700;
  font-size: 16px;
  padding: 5px 15px;

  &:hover {
    background-color: var(--red-color);
    color: white;
  }
}
.filtre-item .router-link-exact-active .filtre-name {
  background-color: var(--red-color) !important;
}
</style>
