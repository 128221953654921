<template>
  <div v-if="campaign" id="commercant-banner">
    <div class="content" :style="styles">
      <h2>{{ campaign.getName($l) }}</h2>
      <button
        type="button"
        @click="loadProducts"
      >
        {{ $t('shop.seeProducts') }}
      </button>
    </div>
  </div>
</template>

<script>
// import ProduitItemModal from './ProduitItemModal.vue'

export default {
  name: 'CommercantBanner',
  components: {
    // ProduitItemModal
  },
  data: () => ({
    loading: false,
    campaign: null,
    openPopup: false
  }),
  computed: {
    shop() {
      return this.$store.getters['shop/current']
    },
    styles() {
      if (!this.campaign.image) {
        return null
      }
      return {
        backgroundImage: `url('${this.$resize(this.campaign.image.url, { w: 1200, h: 616, f: 'outside' })}')`
      }
    }
  },
  watch: {
    'shop.id': 'load'
  },
  mounted() {
    return this.load()
  },
  methods: {
    load() {
      this.loading = true
      return this.$store.dispatch('shop/promotionsCampaigns', { id: this.shop.id })
        .then(campaigns => {
          this.campaign = campaigns.find(campaign => campaign.promotions_type === 'highlight')
        })
        .catch(err => this.$err(err))
        .finally(() => (this.loading = false))
    },

    loadProducts() {
      this.$router.push({ name: 'shop', query: { campaign: this.campaign.id } })
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit SCSS to this component only -->
<style lang="scss" scoped>
@import '../../assets/styles/youpaq.scss';

#commercant-banner {
  margin: 30px 0;
  position: relative;
  @include media-breakpoint-up(lg) {
    margin: 50px 0;
  }
}
.content {
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  box-shadow: 0px 1px 7px #0000005e;
  min-height: 300px;
  border: 10px solid white;
  display: flex;
  align-content: stretch;
  align-items: center;
  flex-direction: column;
  justify-content: center;
  padding: 30px;

  @include media-breakpoint-up(md) {
    min-height: 480px;
  }
}
.content h2 {
  font-weight: 900;
  color: white;
  font-size: 30px;

  @include media-breakpoint-up(lg) {
    font-size: 40px;
  }

  @include media-breakpoint-up(lg) {
    font-size: 50px;
  }
}
.content button {
  background-color: var(--red-color);
  font-size: 20px;
  font-family: inherit;
  color: white;
  font-weight: 700;
  cursor: pointer;
  border-radius: 10px;
  border: 0;
  padding: 12px 30px;
  box-shadow: 1px 1px 6px #00000085;
}
#commercant-banner-pp {
  background-color: var(--red-color);
  box-shadow: 1px 1px 6px #00000085;
  padding: 40px 30px;
  right: 0;
  top: 0;
  position: absolute;
  z-index: 100;

  @include media-breakpoint-up(lg) {
    padding: 30px 30px 130px 30px;
  }

  .close-icon {
    text-align: right;
    img {
      width: 30px;
      cursor: pointer;
    }
  }
}
#commercant-banner-pp:not(.active) {
  display: none;
}
#commercant-banner-pp h3 {
  color: white;
  font-size: 25px;
  font-weight: 900;
  text-transform: uppercase;
  padding-bottom: 20px;

  @include media-breakpoint-up(md) {
    font-size: 30px;
    padding-bottom: 50px;
  }

  @include media-breakpoint-up(lg) {
    font-size: 40px;
  }
}
.commercant-banner-pp-produits {
  display: flex;
  justify-content: center;
  @include media-breakpoint-up(lg) {
    padding: 0 80px;
  }
}

.commercant-banner-pp-produits > .produit-item img {
  max-width: 100%;
}
</style>
