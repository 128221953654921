<template>
  <div id="commercant-content" class="container-fluid">
    <div class="row">
      <div class="commercant-left d-none d-lg-flex col-md-3">
        <CommercantFiltres :partner-id="partner.id" @loaded="v => $emit('loaded', v)" />
      </div>

      <div class="commercant-right col-12 col-lg-9">
        <Breadcrumb class="pb-lg-5" />
        <div class="favoris-search">
          <div
            class="commercant-favoris"
            :class="{ favoris: commercantFavoris }"
          >
            <button @click="toggleFavourite">
              <template v-if="!favorisActive">
                <img src="@/assets/commercant-favoris-empty.png" />
                <span>{{ $t('shop.addFavourite') }}</span>
              </template>
              <template v-else>
                <img src="@/assets/commercant-favoris-full.png" />
                <span>{{ $t('shop.removeFavourite') }}</span>
              </template>
            </button>
          </div>
          <div>
            <router-link :to="{ name: 'shops' }" class="d-flex align-items-center">
              <img src="@/assets/continue-buying-icon.png">
              <span>{{ $t('shop.back') }}</span>
            </router-link>
          </div>
          <div class="d-none d-lg-flex">
            <Searchform v-model="search" button-color="red" />
          </div>
        </div>
        <CommercantBanner />
        <div class="d-flex justify-content-center pb-5 mb-5 mb-md-0 d-lg-none">
          <Searchform v-model="search" button-color="red" />
        </div>
        <a id="products"></a>
        <Produits :shop-id="shop.id" :shop-slug="shop.slug" :search="search" />
      </div>
    </div>
  </div>
</template>

<script>
import CommercantFiltres from './CommercantFiltres.vue'
import Searchform from '@/components/UI/Searchform.vue'
import CommercantBanner from './CommercantBanner.vue'
import Produits from '@/components/Produits/Produits.vue'
import Breadcrumb from '@/components/UI/Breadcrumb'

export default {
  name: 'CommercantContent',
  components: {
    CommercantFiltres,
    Searchform,
    CommercantBanner,
    Produits,
    Breadcrumb
  },
  data: () => ({
    commercantFavoris: false,
    loading: false,
    search: null
  }),
  computed: {
    partner() {
      return this.$store.getters['partner/current']
    },
    shop() {
      return this.$store.getters['shop/current']
    },
    favorisActive() {
      return this.shop.favourite
    }
  },
  methods: {
    toggleFavourite() {
      if (this.loading) {
        return
      }
      this.loading = true
      return this.$store.dispatch('user/shopFavouriteToggle', { shopId: this.shop.id })
        .then(active => {
          this.shop.favourite = active
        })
        .catch(err => {
          if (err?.response?.status === 401) {
            this.$store.commit('modal/show', {
              component: 'LoginPopup',
              params: {
                returnPath: `${this.$route.path}#shops`
              }
            })
            return
          }
          this.$err(err)
        })
        .finally(() => (this.loading = false))
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit SCSS to this component only -->
<style lang="scss" scoped>
@import '../../assets/styles/youpaq.scss';

#commercant-content {
  padding-bottom: 150px;

  @include media-breakpoint-up(lg) {
    padding-bottom: 180px;
  }
}
.commercant-left {
  padding: 60px 30px;

  @include media-breakpoint-up(md) {
    padding: 95px 30px;
  }

  @include media-breakpoint-up(lg) {
    padding: 145px 40px;
  }
}
.commercant-right {
  padding: 15px;

  @include media-breakpoint-up(lg) {
    padding: 40px 90px 0 0;
  }
}
.favoris-search {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  flex-direction: column;
  margin-top: 20px;

  @include media-breakpoint-up(lg) {
    flex-direction: row;
    align-items: center;
  }
  span {
    color: var(--purple-color);
    font-weight: 700;
    font-size: 18px;
    line-height: 25px;
    text-decoration: none;
    text-align: left;
  }
  img {
    margin-right: 10px;
    width: 45px;
    height: 100%;
  }
  a, a:hover {
    text-decoration: none;
  }
}
.favoris-search > div {
  display: flex;
  align-items: center;
  padding-bottom: 20px;
}
.commercant-favoris button {
  border: none;
  background: transparent;
  padding: 0;
  display: flex;
  align-items: center;
  padding-right: 20px;
}
.commercant-favoris:not(.favoris) {
  button.favoris-full {
    display: none;
  }
  button.favoris-empty {
    display: flex;
    align-items: center;
  }
}
.commercant-favoris.favoris {
  button.favoris-full {
    display: flex;
    align-items: center;
  }
  button.favoris-empty {
    display: none;
  }
}
</style>
