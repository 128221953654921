<template>
  <div id="produits" class="containter-fluid">
    <h2>{{ title || $t('shop.ourProducts') }}</h2>
    <div class="d-flex justify-content-center d-lg-none">
      <CommercantFiltres v-if="!filterId && !filterShops" />
      <FavouriteFiltres v-if="filterShops" :shops="filterShops" />
    </div>
    <div class="produit-liste row">
      <div v-for="product in products" :key="product.id" class="col-12 col-md-6 col-xl-4 produit">
        <ProduitItem
          hide-title
          :shop-id="shopId"
          :shop-slug="shopSlug"
          :product-id="product.id"
          :product="product"
          :offer="product.reseller_offers[0]"
          :image="product.images && product.images.raw.url"
          :favourite.sync="product.favourite"
        />
      </div>
      <div v-if="loading">{{ $t('content.loading') }}</div>
      <div v-if="!loading && !products.length">
        <h4>{{ emptyMessage || $t('shop.noProduct') }}</h4>
      </div>
    </div>
    <button
      class="voir-tous"
      @click="loadProducts($route.query)"
    >
      <template v-if="moreProducts">{{ $t('shop.moreProducts') }}</template>
    </button>
    <div class="produit-bg d-none d-lg-flex" />
  </div>
</template>

<script>
import ProduitItem from '@/components/Produits/ProduitItem.vue'
import CommercantFiltres from '@/components/Commercant/CommercantFiltres.vue'
import FavouriteFiltres from '@/components/Favourite/FavouriteFiltres.vue'

export default {
  name: 'Produits',

  components: {
    ProduitItem,
    CommercantFiltres,
    FavouriteFiltres
  },

  props: {
    filterShops: {
      type: Array
    },
    shopId: {
      type: String
    },
    shopSlug: {
      type: String
    },
    filterId: {
      type: String
    },
    search: {
      type: String
    },
    title: {
      type: String
    },
    emptyMessage: {
      type: String
    }
  },

  data() {
    return {
      loading: false,
      products: [],
      moreProducts: true,
      offset: 0,
      limit: 18
    }
  },

  computed: {
    partnerId() {
      return this.$store.getters['partner/current'].id
    },

    action() {
      if (this.$route.query.favourite) {
        return 'filter/listProducts'
      }
      if (this.filterId) {
        return 'filter/listProducts'
      }
      if (this.search) {
        return 'product/searchInShop'
      }
      return 'shop/products'
    }
  },

  watch: {
    search() {
      this.offset = 0
      this.loading = false
      this.products = []
      return this.loadProducts()
    },
    '$route.query'(v) {
      this.offset = 0
      this.loading = false
      this.products = []
      return this.loadProducts(v)
    }
  },

  mounted() {
    // return this.loadProducts(this.$route.query)
    this.$root.$on('reload-products', this.loadProducts)
    return this.loadProducts(this.$route.query)
  },

  beforeDestroy() {
    this.$root.$off('reload-products', this.loadProducts)
  },

  methods: {
    async loadProducts(params = {}) {
      if (this.loading) {
        return
      }
      this.loading = true

      let filterId = this.filterId
      if (this.$route.query.favourite) {
        const filters = await this.$store.dispatch('partner/filters', { id: this.partnerId })
        const filter = filters.find(f => f.mode === 'favourite')
        filterId = filter.id
      }

      return this.$store
        .dispatch(this.action, {
          ...params,
          filterId,
          shopId: this.shopId,
          id: filterId || this.shopId,
          offset: this.offset,
          limit: this.limit,
          search: this.search
        })
        .then(products => {
          this.products = this.products.concat(products).filter(p => p.reseller_offers?.length > 0)
          this.offset += this.limit
          this.moreProducts = products.length === this.limit
        })
        .catch(err => this.$err(err))
        .finally(() => (this.loading = false))
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit SCSS to this component only -->
<style lang="scss" scoped>
@import '../../assets/styles/youpaq.scss';

#produits {
  position: relative;
}
.produit-liste {
  position: relative;
  z-index: 2;
}
.produit-liste .produit {
  padding: 0 20px;
  margin-bottom: 40px;

  @include media-breakpoint-up(lg) {
    padding: 0 40px;
    margin-bottom: 40px;
  }
}
.produit-bg {
  position: absolute;
  top: 60px;
  left: 5%;
  right: 5%;
  bottom: 10px;
  border: 15px solid var(--red-color);
  z-index: 1;
  @include media-breakpoint-up(lg) {
    left: 15%;
    right: 15%;
  }
}
h2 {
  z-index: 2;
  display: inline-block;
  background-color: white;
  padding: 0 20px;
  position: relative;
  font-size: 30px;
  font-weight: 900;
  text-transform: uppercase;
  margin-bottom: 20px;
  @include media-breakpoint-up(md) {
    font-size: 40px;
    margin-bottom: 40px;
    margin-top: 40px;
  }
}
.voir-tous {
  margin-top: 20px;
  display: inline-block;
  margin-right: 0;
  margin-left: auto;
  text-align: right;
  font-size: 25px;
  font-weight: 700;
  color: var(--gray-color);
  background-color: white;
  position: relative;
  z-index: 2;
  text-decoration: none;
  padding: 0 20px;

  @include media-breakpoint-up(lg) {
    margin-top: 70px;
  }
}
button {
  border: none;
}
button.active {
  color: transparent;
  background: transparent;
  cursor: default;
}
.voir-plus-produits:not(.active) {
  display: none;
}
.voir-plus-produits.active {
  display: flex;
}
</style>
