<template>
  <div v-if="!loading">
    <CommercantHeader />
    <CommercantContent @loaded="scrollInitialized = true" />
  </div>
</template>

<script>
import CommercantHeader from '@/components/Commercant/CommercantHeader'
import CommercantContent from '@/components/Commercant/CommercantContent'
import ScrollPromise from '@/mixins/ScrollPromise'

export default {
  name: 'ViewCommercant',
  components: {
    CommercantHeader,
    CommercantContent
  },

  mixins: [ScrollPromise],

  beforeRouteUpdate(to, from, next) {
    if (!to.params.shopId) {
      return this.$store.dispatch('shop/getBySlug', { slug: to.params.shopSlug })
        .then(shop => {
          to.params.shopId = shop.id
          next()
        })
        .catch(next)
    }
    next()
  },

  props: {
    shopId: {
      type: String,
      required: true
    }
  },
  data: () => ({
    loading: true
  }),
  watch: {
    $route: 'load'
  },

  created() {
    return this.load()
  },

  methods: {
    load() {
      this.loading = true
      this.$store.commit('load/loading', { loading: true })
      return Promise
        .all([
          this.$store.dispatch('shop/get', { id: this.shopId }),
          this.$store.dispatch('shop/banners', { shopId: this.shopId })
        ])
        .catch(err => this.$err(err))
        .finally(() => {
          this.loading = false
          this.$store.commit('load/loading', { loading: false })
        })
    }
  }
}
</script>

<style lang="scss">
@import '../assets/styles/youpaq.scss';
/* Commercant Header slider */
#commercant-header .agile .agile__nav-button {
  background: transparent !important;
  border: none;
  position: absolute;
  bottom: 200px;
}
#commercant-header .agile__actions {
  position: absolute;
  width: 100%;
}
#commercant-header .agile__nav-button--prev {
  left: 50px;
}
#commercant-header .agile__nav-button--next {
  right: 50px;
}
#commercant-header .agile__dots {
  position: absolute;
  bottom: 0px;
  left: calc(50% - 50px);
}
#commercant-header .agile__dots .agile__dot button{
  background-color: transparent;
  width: 15px !important;
  height: 15px !important;
  margin: 10px;
  background-size: contain !important;
  border: none;
  background: url("~@/assets/slider-dot-red.svg") no-repeat;
  filter: drop-shadow(2px 2px 2px rgba(0, 0, 0, 0.2)) !important;
  cursor: pointer;
}
#commercant-header .agile__dot--current button{
  background: url("~@/assets/slider-dot-red-current.svg") no-repeat !important;
}
</style>
