<template>
  <div id="commercant-filtres">
    <div v-if="shops.length" class="title">
      <img src="@/assets/filtres-icon.png">{{ $t('favourite.filter') }}
    </div>
    <div v-if="shops.length" class="row">
      <div class="filtres">
        <div v-for="shop in shops" :key="shop.key" class="filtre-item">
          <router-link :to="{ name: $route.name, query: getShopQuery(shop), hash: '#products' }">
            <span class="filtre-name">{{ shop.name }}</span>
          </router-link>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'FavouriteFiltres',
  props: {
    shops: { type: Array }
  },
  watch: {
    'shops.length': 'init'
  },
  mounted() {
    this.init()
  },
  methods: {
    init() {
      if (this.shops.length && !this.$route.query.shop) {
        // select first filter if none is selected
        this.$router.push({ name: this.$route.name, query: this.getShopQuery(this.shops[0]) })
      }
    },

    getShopQuery(shop) {
      return {
        shop: shop.id
      }
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit SCSS to this component only -->
<style lang="scss" scoped>
@import '../../assets/styles/youpaq.scss';

#commercant-filtres {
  text-align: center;
  padding-bottom: 30px;
  @include media-breakpoint-up(lg) {
    text-align: left;
  }
}
.title {
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--purple-color);
  font-size: 16px;
  font-weight: 600;
  margin-bottom: 10px;

  @include media-breakpoint-up(lg) {
    justify-content: flex-start;
  }
  @include media-breakpoint-up(lg) {
    font-size: 20px;
  }

  img {
    height: 20px;
    padding-right: 10px;
  }
}
.filtres {
  display: flex;
  justify-content: center;
  max-width: 100vw;
  flex-wrap: wrap;
  @include media-breakpoint-up(md) {
    display: block;
  }
}
.filtre-item {
  padding: 0 10px 10px;
  @include media-breakpoint-up(md) {
    padding: 0px 0px 10px 0px;
  }
}
.filtre-item a {
  display: flex;
  align-items: center;
  text-decoration: none;
  display: inline-block;
}
.filtre-item a .filtre-name {
  color: white;
  background-color: var(--purple-color);
  font-weight: 700;
  font-size: 16px;
  padding: 5px 15px;

  &:hover {
    background-color: var(--red-color);
    color: white;
  }
}
.filtre-item .router-link-exact-active .filtre-name {
  background-color: var(--red-color) !important;
}
</style>
