import { render, staticRenderFns } from "./Produits.vue?vue&type=template&id=1ca4ce88&scoped=true&"
import script from "./Produits.vue?vue&type=script&lang=js&"
export * from "./Produits.vue?vue&type=script&lang=js&"
import style0 from "./Produits.vue?vue&type=style&index=0&id=1ca4ce88&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1ca4ce88",
  null
  
)

export default component.exports